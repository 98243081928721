import React from 'react';

const TermsAndConditions = () => {
  return (
    <div>
      <h2>Terms and Conditions</h2>
      <p>This is the content of the Terms and Conditions page.</p>
    </div>
  );
};

export default TermsAndConditions;

  