import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h2>Privacy Policy</h2>
      <p>This is the content of the cookie policy page.</p>
    </div>
  );
};

export default PrivacyPolicy;